import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProductNav from '../../components/ProductNav'
import Button from '../../components/Button'
import ColumnStack from '../../components/ColumnStack'
import ResponsiveHidden from '../../components/ResponsiveHidden'
import FormConfirm from '../../components/FormConfirm'
import DataBasePostcards from '../../components/DataBasePostcards'
import { Box } from 'grommet'

const PostcardsConfirm = () => (
  <Layout productNav="postcards">
    <SEO title="Postcards - Confirm Order" />
    <Box pad={{ vertical: 'medium' }} fill>
      <DataBasePostcards Component={FormConfirm} />
    </Box>
  </Layout>
)

export default PostcardsConfirm
